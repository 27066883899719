import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { MessageService } from 'primeng/api';

@Directive({
  selector: 'form [formGroup]',
})
export class FormSubmitDirective {
  constructor(
    private el: ElementRef<HTMLElement>,
    private renderer: Renderer2,
    private fg: FormGroupDirective,
    private messageService: MessageService
  ) {}

  @HostListener('submit', ['$event'])
  onSubmit(event: any) {
    this.setLeafClass('.ng-invalid[formControlName]');

    if (this.fg) {
      if (this.fg.invalid) {
        this.messageService.add({
          severity: 'error',
          summary: 'Form',
          detail: 'Dati del form errati o incompleti',
        });
      }
    }
  }

  setLeafClass(selector: string) {
    const invalidClass = 'ng-dirty';
    const parentElement = this.el.nativeElement;
    const invalidNode = parentElement.querySelectorAll(selector);
    invalidNode.forEach((n) => {
      n.classList.remove(invalidClass);
      if (!n.querySelectorAll(selector)[0]) {
        n.classList.add(invalidClass);
      }
    });
  }
}
