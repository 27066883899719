import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
   DepartmentEntity,
   DepartmentService,
   OrganizationEntity,
   OrganizationService,
   RoleEntity,
   RoleService,
   UserCompoundUpsertRequestDTO,
   UserFindOneResponseDTO,
   UserService,
} from '@app/app/client';
import { PasswordMatchValidator } from '@app/app/validators/password-match.validator';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { filter, switchMap, tap, zip } from 'rxjs';

interface UserForm {
   id: FormControl;
   firstname: FormControl;
   lastname: FormControl;
   username: FormControl;
   email: FormControl;
   password: FormControl;
   confirmPassword: FormControl;
   active: FormControl;
   roles: FormControl;
   organization: FormControl;
   department: FormControl;
}

@Component({
   selector: 'user-upsert-modal',
   templateUrl: './user-upsert.modal.html',
})
export class UserUpsertModal implements OnInit {
   userId?: number;
   user?: UserFindOneResponseDTO;
   userForm: FormGroup<UserForm> = new FormGroup(
      {
         id: new FormControl(null),
         firstname: new FormControl(null, [Validators.required]),
         lastname: new FormControl(null, [Validators.required]),
         username: new FormControl(null, [Validators.required]),
         email: new FormControl(null, [Validators.required]),
         password: new FormControl(null),
         confirmPassword: new FormControl(null),
         active: new FormControl(null),
         roles: new FormControl(null, [Validators.required]),
         organization: new FormControl(null, [Validators.required]),
         department: new FormControl(null),
      },
      [PasswordMatchValidator('password', 'confirmPassword') /* PasswordFormatValidator('id', 'password') */],
   );
   rolesList: RoleEntity[] = [];
   organizationList: OrganizationEntity[] = [];
   departmentList: DepartmentEntity[] = [];

   constructor(
      private userService: UserService,
      private roleService: RoleService,
      private organizationService: OrganizationService,
      private departmentService: DepartmentService,
      private messageService: MessageService,
      private ref: DynamicDialogRef,
      public config: DynamicDialogConfig,
   ) {}

   ngOnInit(): void {
      this.userId = this.config.data.userId;

      zip([this.roleService.findAll(), this.organizationService.findAll()])
         .pipe(
            tap((res) => {
               this.rolesList = res[0];
               this.organizationList = res[1];
            }),
            filter(() => this.userId != null),
            switchMap(() => this.userService.findOne(this.userId!)),
            tap((res) => (this.user = res)),
            switchMap(() =>
               this.departmentService.search({ where: { organizationId: this.user?.Department?.organizationId } }),
            ),
            tap((res) => (this.departmentList = res.data)),
            tap(() => {
               this.userForm.patchValue({
                  firstname: this.user?.Contact?.name,
                  lastname: this.user?.Contact?.surname,
                  active: this.user?.active,
                  email: this.user?.email,
                  roles: this.user?.Role?.map((r) => r.id),
                  username: this.user?.username,
                  organization: this.user?.organizationId,
                  department: this.user?.departmentId,
               });
            }),
         )
         .subscribe();
   }

   onOrganizationChange(organizationId: number) {
      this.departmentService
         .search({ where: { organizationId } })
         .pipe(tap((res) => (this.departmentList = res.data)))
         .subscribe();
   }

   submit() {
      if (this.userForm && this.userForm.valid) {
         let rawForm = this.userForm.value;

         let user: UserCompoundUpsertRequestDTO = {
            user: {
               id: this.user?.id,
               username: rawForm.username,
               email: rawForm.email,
               password: rawForm.password || undefined,
               active: rawForm.active || false,
               organizationId: rawForm.organization,
               departmentId: rawForm.department,
            },
            contact: {
               id: this.user?.contactId,
               name: rawForm.firstname,
               surname: rawForm.lastname,
            },
            roles: rawForm.roles.map((r: number) => r),
         };

         this.userId ? this.update(this.userId, user) : this.save(user);
      }
   }

   update(id: number, user: UserCompoundUpsertRequestDTO) {
      this.userService.update(id, user).subscribe((res) => {
         this.messageService.add({
            severity: 'success',
            summary: 'Utente aggiornato correttamente',
         });
         this.ref.close(res);
      });
   }

   save(user: UserCompoundUpsertRequestDTO) {
      this.userService.create(user).subscribe((res) => {
         this.messageService.add({
            severity: 'success',
            summary: 'Utente salvato correttamente',
         });
         this.ref.close(res);
      });
   }
}
