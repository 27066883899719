/**
 * Skillup
 * The Skillup API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface OrganizationEntity { 
    id?: number;
    name: string;
    referentName?: string;
    referentSurname?: string;
    referentEmail?: string;
    referentPhone?: string;
    frameworksLimit?: number;
    campaignLimit?: number;
    userLimit?: number;
    assessmentLimit?: number;
    legalAddress?: string;
    vatNumber?: string;
    fiscalCode?: string;
    pec?: string;
    sdiCode?: string;
}

