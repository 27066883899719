/**
 * Skillup
 * The Skillup API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProfileEntity } from './profileEntity';


export interface GetNoCoveredProfileListResponseDTO { 
    id?: number;
    assessmentId: number;
    profileId: number;
    proximity: number;
    Profile: ProfileEntity;
}

