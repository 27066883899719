import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserPayloadEntity } from '@app/app/client';
import { CachedRequestsService } from '@app/app/services/cached-requests';
import { LoginService } from '@app/app/services/login.service';
import { MenuItem } from 'primeng/api';

@Component({
   selector: 'avatar',
   templateUrl: './avatar.component.html',
})
export class AvatarComponent implements OnInit {
   @Input() options?: { menu: boolean };
   userInfo?: UserPayloadEntity;
   avatarMenu?: MenuItem[];

   constructor(
      private cachedRequests: CachedRequestsService,
      private router: Router,
      private loginService: LoginService,
   ) {}

   ngOnInit(): void {
      this.avatarMenu = [
         {
            label: 'User',
            items: [
               {
                  label: 'Logout',
                  icon: 'pi pi-power-off',
                  command: () => {
                     this.logout();
                  },
               },
            ],
         },
      ];

      this.userInfo = this.loginService.user;
   }

   logout() {
      localStorage.removeItem('access_token');
      localStorage.removeItem('user_info');
      this.router.navigate(['/login']);
   }
}
