import { NgModule } from '@angular/core';
import { CommonKitModule } from '../common-kit.module';
import { UserUpsertModal } from '../pages/user/upsert-modal/user-upsert-modal.component';
import { HelpManualModal } from './help-manual-modal/help-manual-modal.component';

@NgModule({
   declarations: [UserUpsertModal, HelpManualModal],
   imports: [CommonKitModule],
   providers: [],
})
export class ModalsModule {}
