import { Component, ViewEncapsulation } from '@angular/core';
import { SpinnerService } from './spinner.service';

@Component({
  selector: 'spinner-component',
  template: `
    <div
      class="surface-100 opacity-60 absolute top-0 bottom-0 right-0 left-0 z-1"
      *ngIf="spinnerService.httpLoading"
    >
      <div class="flex h-full">
        <div class="flex-grow-1 flex align-items-center justify-content-center">
          <p-progressSpinner></p-progressSpinner>
        </div>
      </div>
    </div>
  `,
  encapsulation: ViewEncapsulation.None,
})
export class SpinnerComponent {
  constructor(public spinnerService: SpinnerService) {}
}
