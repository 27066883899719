/**
 * Skillup
 * The Skillup API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CompetenceCoverageEntity } from './competenceCoverageEntity';
import { GetCoverageByAssessmentResponseCompetenceLevelDto } from './getCoverageByAssessmentResponseCompetenceLevelDto';
import { AreaEntity } from './areaEntity';
import { GetCoverageByAssessmentResponseSet } from './getCoverageByAssessmentResponseSet';


export interface GetCoverageByAssessmentResponseCompetenceWithCoverageDto { 
    id?: number;
    code: string;
    name?: string;
    text: string;
    areaId: number;
    Area: AreaEntity;
    CompetenceLevel: Array<GetCoverageByAssessmentResponseCompetenceLevelDto>;
    CompetenceCoverage: Array<CompetenceCoverageEntity>;
    Set: Array<GetCoverageByAssessmentResponseSet>;
}

