/**
 * Skillup
 * The Skillup API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AssessmentUpsertRequestDTO { 
    id?: number;
    campaignId: number;
    state: number;
    consolidatorState: number;
    progress: number;
    createdAt: string;
    updatedAt: string;
    startedAt: string;
    endedAt: string;
    consolidatorStartedAt: string;
    consolidatorEndedAt: string;
    userId: number;
    consolidatorId: number;
}

