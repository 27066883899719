import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpStatusCode,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ICustomError } from '../interfaces/custom-error.interface';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
  constructor(private router: Router, private messageService: MessageService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const access_token = localStorage.getItem('access_token') || '';
    request = request.clone({
      setHeaders: { Authorization: `Bearer ${access_token}` },
    });

    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        const status = err.status;
        const customError = <ICustomError>err.error;

        if (status === HttpStatusCode.Unauthorized) {
          this.router.navigate(['/login']);
        }

        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: customError.message,
        });

        return throwError(() => err);
      })
    );
  }
}
