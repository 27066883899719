import { Pipe, PipeTransform } from '@angular/core';
import { RoleEntity } from '../client';

@Pipe({ name: 'rolesList' })
export class RolesListPipe implements PipeTransform {
   transform(roles: RoleEntity[]) {
      if (roles) {
         let roleLabels = roles.map((r) => r.label);
         return roleLabels.join(' ');
      }
      return;
   }
}
