import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppTemplateComponent } from './components/app-template/app-template.component';
import { AdminGuard } from './guards/auth.guard';

const routes: Routes = [
   {
      path: '',
      redirectTo: 'admin',
      pathMatch: 'full',
   },
   {
      path: '',
      component: AppTemplateComponent,
      children: [
         {
            path: 'admin',
            canActivate: [AdminGuard],
            loadChildren: () => import('./pages/admin/admin.module').then((m) => m.AdminModule),
         },
         {
            path: 'assessment',
            loadChildren: () => import('./pages/assessment/assessment.module').then((m) => m.AssessmentModule),
         },
      ],
   },
];

@NgModule({
   imports: [RouterModule.forRoot(routes)],
   exports: [RouterModule],
})
export class AppRoutingModule {}
