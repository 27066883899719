/**
 * Skillup
 * The Skillup API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SetAnswerEntity } from './setAnswerEntity';
import { SetCoverageEntity } from './setCoverageEntity';


export interface GetCoverageByAssessmentResponseSet { 
    id?: number;
    code: string;
    text: string;
    competenceId: number;
    SetCoverage: Array<SetCoverageEntity>;
    SetAnswer: Array<SetAnswerEntity>;
}

