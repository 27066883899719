import { NgModule } from '@angular/core';
import { FormSubmitDirective } from './form-submit.directive';
import { RoleDirective } from './role.directive';
import { TableRowDirective } from './table-row.directive';

const components: any = [FormSubmitDirective, RoleDirective, TableRowDirective];

@NgModule({
   declarations: components,
   exports: components,
   providers: [],
})
export class DirectivesModule {}
