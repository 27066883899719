import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { SpinnerComponent } from './spinner.component';
import { SpinnerService } from './spinner.service';

const COMPONENTS = [SpinnerComponent];

@NgModule({
  imports: [CommonModule, ProgressSpinnerModule],
  declarations: COMPONENTS,
  exports: COMPONENTS,
  providers: [
    SpinnerService,
  ],
})
export class SpinnerModule {}
