import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HeaderService } from './header/header.service';
@Component({
   selector: 'app-template',
   templateUrl: './app-template.component.html',
   styleUrls: ['./app-template.component.scss'],
})
export class AppTemplateComponent implements OnInit {
   constructor(public headerService: HeaderService) {}

   ngOnInit() {}
}
