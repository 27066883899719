/**
 * Skillup
 * The Skillup API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RoleEntity } from './roleEntity';
import { DepartmentEntity } from './departmentEntity';
import { ContactEntity } from './contactEntity';


export interface UserPayloadEntity { 
    id: number;
    email: string;
    username: string;
    active: boolean;
    contactId: number;
    Contact?: ContactEntity;
    Role?: Array<RoleEntity>;
    departmentId: number;
    Department?: DepartmentEntity;
    organizationId: number;
    isSuperAdmin: boolean;
}

