/**
 * Skillup
 * The Skillup API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { GetCoverageByAssessmentResponseReleaseCoverageDto } from './getCoverageByAssessmentResponseReleaseCoverageDto';


export interface GetCoverageByAssessmentResponseCampaignCoverageDto { 
    id?: number;
    code: string;
    name: string;
    releaseId?: number;
    startAt?: string;
    endLimitAt?: string;
    endedAt?: string;
    state: number;
    Release: GetCoverageByAssessmentResponseReleaseCoverageDto;
}

