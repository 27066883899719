import { Directive, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { HeaderService } from './header.service';
@Directive({
   selector: '[header-left]',
})
export class HeaderLeftDirective implements OnInit, OnDestroy {
   constructor(public templateRef: TemplateRef<any>, private headerService: HeaderService) {}
   ngOnDestroy(): void {
      this.headerService.headerLeftDirective.pop();
   }
   ngOnInit(): void {
      this.headerService.headerLeftDirective.push(this);
   }
}

@Directive({
   selector: '[header-right]',
})
export class HeaderRightDirective implements OnInit, OnDestroy {
   constructor(public templateRef: TemplateRef<any>, private headerService: HeaderService) {}
   ngOnDestroy(): void {
      this.headerService.headerRightDirective.pop();
   }
   ngOnInit(): void {
      this.headerService.headerRightDirective.push(this);
   }
}
