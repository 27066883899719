/**
 * Skillup
 * The Skillup API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UserContactUpsertRequestDTO { 
    id?: number;
    name: string;
    surname: string;
    address?: string;
    otherAddresses?: Array<string>;
    otherPhones?: Array<string>;
    phone?: string;
}

