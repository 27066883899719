/**
 * Skillup
 * The Skillup API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CompetenceLevelCoverageEntity } from './competenceLevelCoverageEntity';
import { GetCoverageByAssessmentResponseCompetenceLevelAnswerDto } from './getCoverageByAssessmentResponseCompetenceLevelAnswerDto';
import { GetCoverageByAssessmentResponseSet } from './getCoverageByAssessmentResponseSet';


export interface GetCoverageByAssessmentResponseCompetenceLevelDto { 
    id?: number;
    code: number;
    text: string;
    competenceId: number;
    CompetenceLevelAnswer: Array<GetCoverageByAssessmentResponseCompetenceLevelAnswerDto>;
    CompetenceLevelCoverage: Array<CompetenceLevelCoverageEntity>;
    Set: Array<GetCoverageByAssessmentResponseSet>;
}

