import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { finalize, switchMap, tap } from 'rxjs/operators';
import { SpinnerService } from './spinner.service';

@Injectable()
export class SpinnerInterceptor implements HttpInterceptor {
  private count = 0;

  constructor(private spinnerService: SpinnerService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (req.url.match(/.*\/refreshToken/)) {
      return next.handle(req);
    }
    const urlParams = new URLSearchParams(new URL(req.url).search);

    return of(req).pipe(
      tap(() => {
        this.spinnerService.setHttpProgressStatus(true);
        this.count++;
      }),
      switchMap((request) => next.handle(request)),
      finalize(() => {
        this.count--;
        if (!this.count) {
          this.spinnerService.setHttpProgressStatus(false);
        }
      })
    );
  }
}
