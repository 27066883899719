/**
 * Skillup
 * The Skillup API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { GetProfileCoverageResponseSetCoverageDto } from './getProfileCoverageResponseSetCoverageDto';
import { AreaEntity } from './areaEntity';
import { SetEntity } from './setEntity';


export interface GetProfileCoverageResponseCompetenceProfileCoverageDto { 
    id?: number;
    code: string;
    name?: string;
    text: string;
    areaId: number;
    Set: Array<SetEntity>;
    Area: AreaEntity;
    profilePercentage?: number;
    competencePercentage?: number;
    setCoverageList?: Array<GetProfileCoverageResponseSetCoverageDto>;
}

