/**
 * Skillup
 * The Skillup API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { GetProfileCoverageResponseUserDto } from './getProfileCoverageResponseUserDto';
import { GetProfileCoverageResponseCompetenceProfileCoverageDto } from './getProfileCoverageResponseCompetenceProfileCoverageDto';
import { ProfileEntity } from './profileEntity';


export interface GetProfileCoverageResponseDto { 
    user: GetProfileCoverageResponseUserDto;
    competencesList: Array<GetProfileCoverageResponseCompetenceProfileCoverageDto>;
    profile: ProfileEntity;
    profileProximity: number;
}

