/**
 * Skillup
 * The Skillup API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CompetenceCoverageEntity } from './competenceCoverageEntity';
import { AreaEntity } from './areaEntity';
import { GetAssessmentResponseCompetenceLevelDto } from './getAssessmentResponseCompetenceLevelDto';
import { GetAssessmentResponseCompetenceAnswerDto } from './getAssessmentResponseCompetenceAnswerDto';
import { GetAssessmentResponseSetDto } from './getAssessmentResponseSetDto';


export interface GetAssessmentResponseCompetenceDto { 
    id?: number;
    code: string;
    name?: string;
    text: string;
    areaId: number;
    Area?: AreaEntity;
    CompetenceLevel: Array<GetAssessmentResponseCompetenceLevelDto>;
    CompetenceCoverage?: Array<CompetenceCoverageEntity>;
    CompetenceAnswer?: Array<GetAssessmentResponseCompetenceAnswerDto>;
    Set: Array<GetAssessmentResponseSetDto>;
}

