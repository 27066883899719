import { Pipe, PipeTransform } from '@angular/core';
import { ContactEntity } from '../client';

@Pipe({ name: 'contactFirstLetters' })
export class ContactFirstLettersPipe implements PipeTransform {
   transform(contact: ContactEntity) {
      let firstLetters = '';
      if (contact) {
         if (contact.name?.length) firstLetters += contact.name[0].toUpperCase();
         if (contact.surname?.length) firstLetters += contact.surname[0].toUpperCase();
      }
      return firstLetters;
   }
}
