/**
 * Skillup
 * The Skillup API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserContactUpsertRequestDTO } from './userContactUpsertRequestDTO';
import { UserUpsertRequestDTO } from './userUpsertRequestDTO';


export interface UserCompoundUpsertRequestDTO { 
    user: UserUpsertRequestDTO;
    contact: UserContactUpsertRequestDTO;
    roles: Array<number>;
}

