import { Injectable } from '@angular/core';
import { Observable, shareReplay } from 'rxjs';
import { DomainValueEntity, DomainValueService } from '../client';

@Injectable()
export class CachedRequestsService {
   domainValueList$?: Observable<DomainValueEntity[]>;

   constructor(private domainValueService: DomainValueService) {}

   getDomainValues() {
      if (!this.domainValueList$) {
         this.domainValueList$ = this.domainValueService.findAll().pipe(shareReplay());
      }
      return this.domainValueList$;
   }
}
