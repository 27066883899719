import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { LoginService } from '../services/login.service';
import { RoleEnum } from '../commons/enums/user.enum';

@Directive({ selector: '[role]' })
export class RoleDirective {
   constructor(
      private viewContainer: ViewContainerRef,
      private templateRef: TemplateRef<any>,
      private loginService: LoginService,
   ) {}

   OnInit() {}

   @Input()
   set role(acceptedRoles: RoleEnum[]) {
      if (acceptedRoles) {
         let user = this.loginService.user;

         let roles = user.Role;
         if (roles?.find((r) => acceptedRoles.some((a) => a == r.code))) {
            this.viewContainer.createEmbeddedView(this.templateRef);
            return;
         }
         this.viewContainer.clear();
      }
   }
}
