import { Injectable } from '@angular/core';
import { Observable, switchMap, tap } from 'rxjs';
import { AuthService, UserPayloadEntity } from '../client';

@Injectable()
export class LoginService {
   constructor(private authService: AuthService) {}

   login(username: string, password: string): Observable<UserPayloadEntity> {
      return this.authService.login({ username, password }).pipe(
         tap((res) => {
            localStorage.setItem('access_token', res.access_token);
         }),
         switchMap(() => this.authService.getUserInfo()),
         tap((res) => {
            localStorage.setItem('user', JSON.stringify(res));
         }),
      );
   }

   get user(): UserPayloadEntity {
      let userStorage = localStorage.getItem('user');
      return JSON.parse(userStorage!);
   }
}
