import { NgModule } from '@angular/core';
import { ContactFirstLettersPipe } from '@app/app/pipes/contact-first-letters.pipe';
import { AvatarModule } from 'primeng/avatar';
import { MenuModule } from 'primeng/menu';
import { AvatarComponent } from './avatar.component';

@NgModule({
   imports: [AvatarModule, MenuModule],
   declarations: [AvatarComponent, ContactFirstLettersPipe],
   exports: [AvatarComponent],
   providers: [],
})
export class AvatarAppModule {}
