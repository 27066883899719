/**
 * Skillup
 * The Skillup API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AreaUpsertRequestDTO { 
    id: number;
    code: string;
    text: string;
    releaseId: number;
    createdAt: string;
    updatedAt: string;
}

