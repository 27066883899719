/**
 * Skillup
 * The Skillup API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RoleEntity } from './roleEntity';


export interface GetCoverageByAssessmentResponseUserWithRoleDto { 
    id?: number;
    email: string;
    username?: string;
    password: string;
    active: boolean;
    isSuperAdmin: boolean;
    contactId: number;
    departmentId?: number;
    organizationId: number;
    Role: Array<RoleEntity>;
}

