import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { LoginService } from '../services/login.service';

@Injectable()
export class AdminGuard {
   constructor(private loginService: LoginService, private router: Router) {}

   canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
      let userInfo = this.loginService.user;

      if (userInfo?.Role?.find((r) => r.code == 'admin' || r.code == 'superadmin')) {
         return true;
      }

      this.router.navigateByUrl('/assessment');
      return false;
   }
}
