<div class="px-4 h-full overflow-scroll">
   <p>
      Ogni competenza &egrave; corredata da una descrizione e dall&rsquo;elenco delle conoscenze e abilit&agrave; che la
      caratterizzano.
   </p>
   <p>
      Ad esempio, la prima competenza che trovi &egrave;
      <strong>Allineamento Strategico IS e di Business,</strong>inclusa nell&rsquo;area PLAN; essa mostra una
      descrizione della competenza e gli insiemi di conoscenze e abilit&agrave; associate che sono
      <strong>Business Analysis and Modelling</strong>, <strong>ICT Strategic Planning</strong>, e cos&igrave; via.
   </p>
   <p>Per ogni competenza devi indicare:</p>
   <p><strong>S&Igrave;, </strong>se possiedi questa competenza, se la pratichi o l&rsquo;hai praticata</p>
   <p><strong>NO, </strong>in caso contrario (non la possiedi, non la pratichi o non l&rsquo;hai praticata)</p>
   <p>
      Ogni volta che selezioni <strong>S&Igrave;</strong>&nbsp;alla competenza, devi indicare COME la pratichi o
      l&rsquo;hai praticata, selezionando <strong>uno o anche pi&ugrave; di uno </strong>dei livelli associati
      (nell&rsquo;esempio dell&rsquo;<strong>Allineamento Strategico IS e di Business</strong>, Livello 4 e/o Livello
      5).
   </p>
   <p>
      Devono essere selezionati <strong><span style="text-decoration: underline">tutti i livelli</span></strong
      >&nbsp;di competenza acquisiti durante il proprio percorso professionale.
   </p>
   <p>Per andare avanti seleziona il pulsante AVANTI.</p>
   <p class="text-center"><strong>Dettagliare la competenza posseduta</strong></p>
   <p>Le conoscenze e abilit&agrave; appartenenti alla competenza vengono elencate in alto.</p>
   <p>A questo punto devi dettagliare meglio le conoscenze e le abilit&agrave; inerenti alla competenza selezionata.</p>
   <p>
      Scegli l&rsquo;insieme di conoscenze/abilit&agrave; e cliccando su , puoi leggere in dettaglio quali Conoscenze e
      Abilit&agrave; caratterizzano l&rsquo;item di valutazione.
   </p>
   <p>Stai bene attento: <strong>NON DEVI AVERE </strong>tutte le conoscenze o le abilit&agrave; che vedi elencate.</p>
   <p>Questo &egrave; un elenco complessivo, pu&ograve; essere sufficiente averne anche solo una parte.</p>
   <p>
      Se non ti riconosci in nessuna delle conoscenze e abilit&agrave; indicate nell&rsquo;elenco, seleziona
      <strong>NO</strong>&nbsp;in &ldquo;<strong>Esperienze lavorative</strong>&rdquo;.
   </p>
   <p>
      Viceversa, se ritieni di avere alcune delle conoscenze e abilit&agrave; elencate, devi dettagliare alcune
      informazioni sulla competenza che stai valorizzando:
   </p>
   <p>- <strong>&laquo;Capacit&agrave; di applicare&raquo;</strong></p>
   <p>- <strong>&laquo;Autonomia&raquo;</strong></p>
   <p>- <strong>&laquo;Complessit&agrave;&raquo;</strong></p>
   <p>&nbsp;</p>
   <p>Vediamole una per una.</p>
   <p>&nbsp;</p>
   <p>
      <strong>&laquo;Capacit&agrave; di applicare&raquo;, </strong>&egrave; il tempo necessario per ritornare operativi
      e praticare conoscenze e abilit&agrave;.
   </p>
   <p>Con la domanda:</p>
   <p><strong>Quando saresti in grado di applicare queste conoscenze e abilit&agrave;?</strong></p>
   <p>Puoi rispondere:</p>
   <p>
      &bull;&nbsp;<strong>&laquo;Subito&raquo; </strong>significa che &egrave; una esperienza attuale o anche passata ma
      che sei in grado di praticare in breve tempo.
   </p>
   <p>
      &bull;&nbsp;<strong>&laquo;In breve tempo&raquo; </strong>indica che, pur non essendo pi&ugrave; praticata,
      &egrave; possibile praticarla in un periodo relativamente breve.
   </p>
   <p>
      &bull;&nbsp;<strong>&laquo;Mi serve pi&ugrave; tempo&raquo; </strong>indica una esperienza passata e lontana e
      legata spesso a conoscenze e abilit&agrave; non pi&ugrave; utilizzate.
   </p>
   <p>&nbsp;</p>
   <p>
      <strong>&laquo;Autonomia&raquo;, </strong>&egrave; il grado di autonomia con cui hai agito o praticato la
      conoscenza e le abilit&agrave; nel contesto di riferimento.
   </p>
   <p>Con la domanda:</p>
   <p>
      <strong
         >Nell&rsquo;applicare queste conoscenze e abilit&agrave; fai scelte, hai ampia delega, responsabilit&agrave;,
         autorit&agrave;? &nbsp;&nbsp;&nbsp;</strong
      >
   </p>
   <p>Puoi rispondere:</p>
   <p>
      &bull;&nbsp;Con <strong>SI </strong>se<strong>&nbsp;</strong>sei/eri in una situazione in cui TU decidevi o avevi
      autonomia sulle scelte
   </p>
   <p>&bull;&nbsp;Con<strong>NO</strong>se rispondi/rispondevi a istruzioni di altri.</p>

   <p>
      <strong>&laquo;Complessit&agrave;&raquo;, </strong>&egrave; il livello di complessit&agrave; del
      contesto<strong>&nbsp;</strong>in cui l&rsquo;insieme di Conoscenze e Abilit&agrave; &egrave; o era stato
      applicato in un&rsquo;esperienza lavorativa attuale o passata. La complessit&agrave; &egrave; determinata dalle
      seguenti caratteristiche:
   </p>
   <p>&bull;&nbsp;contesto prevedibile/non prevedibile e/o contesto strutturato/non strutturato</p>
   <p>&bull;&nbsp;complessit&agrave; organizzativa e/o complessit&agrave; del progetto/processo</p>
   <p>Con le due domande:</p>
   <p><strong>Sei in grado di applicare queste conoscenze e abilit&agrave;:</strong></p>
   <p>
      <strong
         >in un contesto non prevedibile, non strutturato?
         &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong
      >&nbsp;
   </p>
   <p>
      &bull;&nbsp;Con <strong>SI </strong>ritieni che il contesto in cui hai praticato queste conoscenze e
      abilit&agrave; &egrave;/era complesso, imprevedibile, non strutturato, insomma&hellip; non facile
   </p>
   <p>
      &bull;&nbsp;Indica <strong>NO </strong>indichi che sei/eri in una situazione strutturata prevedibile&hellip;
      semplice.
   </p>
   <p>
      <strong>in organizzazioni, progetti, processi complessi?Ø</strong>
   </p>
   <p>
      &bull;&nbsp;Con <strong>SI </strong>ritieni che organizzazione, progetto, processo in cui hai praticato queste
      conoscenze e abilit&agrave; &egrave;/era complesso, imprevedibile, non strutturato, insomma&hellip; non facile
   </p>
   <p>
      &bull;&nbsp;Indica <strong>NO </strong>indichi che sei/eri in un contesto organizzativo, progettuale e di processo
      strutturato prevedibile&hellip; semplice.
   </p>
   <p>&nbsp;</p>
   <p>
      Puoi vedere il report con l&rsquo;elenco delle risposte date, andando sulla campagna selezionata e utilizzando il
      tasto: &laquo;<strong>Risposte</strong>&raquo;.
   </p>
   <p>Avrai l&rsquo;elenco delle risposte che hai dato fino al dettaglio delle conoscenze e abilit&agrave;.</p>
   <p>&nbsp;</p>
   <p>
      Per concludere il processo devi selezionare il tasto in alto &laquo;<strong>Chiudi autovalutazione</strong
      >&raquo;.
   </p>
   <p>Le risposte vengono cos&igrave; congelate e non sar&agrave; pi&ugrave; possibile modificarle.</p>
   <p>
      Successivamente potrai sempre accedere allo strumento di skill mapping, ma non potrai pi&ugrave; modificare le
      risposte date.
   </p>
   <p>&nbsp;</p>
   <p>
      Quando hai valorizzato tutte le competenze, hai modo di vedere o salvare il report con l&rsquo;elenco delle
      competenze che hai valutato.
   </p>
   <p>
      Avrai a video la percentuale di copertura di ogni competenza e, di seguito, il dettaglio di copertura a livello di
      esperienza praticata e delle conoscenze/abilit&agrave; associate.
   </p>
</div>
