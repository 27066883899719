/**
 * Skillup
 * The Skillup API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface DomainValueUpsertRequestDTO { 
    id: number;
    code: number;
    key: string;
    text: string;
    shortText: string;
    value: string;
    tags: Array<string>;
}

