import { Injectable } from '@angular/core';
@Injectable()
export class SpinnerService {
  public httpLoading: boolean = false;
  constructor() {}

  setHttpProgressStatus(inprogess: boolean) {
    setTimeout(() => {
      this.httpLoading = inprogess;
    });
  }
}
