export * from './area.service';
import { AreaService } from './area.service';
export * from './assessment.service';
import { AssessmentService } from './assessment.service';
export * from './auth.service';
import { AuthService } from './auth.service';
export * from './campaign.service';
import { CampaignService } from './campaign.service';
export * from './competence.service';
import { CompetenceService } from './competence.service';
export * from './competenceLevel.service';
import { CompetenceLevelService } from './competenceLevel.service';
export * from './department.service';
import { DepartmentService } from './department.service';
export * from './domainValue.service';
import { DomainValueService } from './domainValue.service';
export * from './organization.service';
import { OrganizationService } from './organization.service';
export * from './profile.service';
import { ProfileService } from './profile.service';
export * from './profileCoverage.service';
import { ProfileCoverageService } from './profileCoverage.service';
export * from './release.service';
import { ReleaseService } from './release.service';
export * from './role.service';
import { RoleService } from './role.service';
export * from './set.service';
import { SetService } from './set.service';
export * from './setCoverageConfig.service';
import { SetCoverageConfigService } from './setCoverageConfig.service';
export * from './skill.service';
import { SkillService } from './skill.service';
export * from './tenant.service';
import { TenantService } from './tenant.service';
export * from './user.service';
import { UserService } from './user.service';
export const APIS = [AreaService, AssessmentService, AuthService, CampaignService, CompetenceService, CompetenceLevelService, DepartmentService, DomainValueService, OrganizationService, ProfileService, ProfileCoverageService, ReleaseService, RoleService, SetService, SetCoverageConfigService, SkillService, TenantService, UserService];
