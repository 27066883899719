import { Directive, Input } from '@angular/core';

interface TableRowTemplateContext<TypedItem extends object> {
  $implicit: TypedItem;
}

@Directive({
  selector: 'ng-template[rowType]'
})
export class TableRowDirective<TypedItem extends object> {

  @Input('rowType') items!: TypedItem[];

  static ngTemplateContextGuard<TContextItem extends object>(
    dir: TableRowDirective<TContextItem>,
    ctx: unknown
  ): ctx is TableRowTemplateContext<TContextItem> {
    return true;
  }

}
