import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '@app/environments/environment';
import { MessageService } from 'primeng/api';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { Configuration } from './client';
import { AppTemplateModule } from './components/app-template/app-template.module';
import { SpinnerInterceptor } from './components/spinner/spinner.interceptor';
import { SpinnerModule } from './components/spinner/spinner.module';
import { AdminGuard } from './guards/auth.guard';
import { HttpRequestInterceptor } from './interceptors/http-request.interceptor';
import { ModalsModule } from './modals/modals-module';
import { LoginModule } from './pages/login/login.module';
@NgModule({
   declarations: [AppComponent],
   imports: [
      LoginModule,
      BrowserAnimationsModule,
      BrowserModule,
      AppRoutingModule,
      HttpClientModule,
      ModalsModule,
      SpinnerModule,
      AppTemplateModule,
   ],
   providers: [
      MessageService,
      AdminGuard,
      { provide: HTTP_INTERCEPTORS, useClass: SpinnerInterceptor, multi: true },
      {
         provide: HTTP_INTERCEPTORS,
         useClass: HttpRequestInterceptor,
         multi: true,
      },
      {
         provide: Configuration,
         useFactory: () =>
            new Configuration({
               basePath: environment.apiUrl,
            }),
         multi: false,
      },
   ],
   bootstrap: [AppComponent],
})
export class AppModule {}
