import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from '@app/app/services/login.service';

@Component({
   selector: 'login',
   templateUrl: `login.component.html`,
})
export class LoginComponent {
   loginForm: FormGroup = new FormGroup({
      login: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required),
   });

   constructor(private router: Router, private loginService: LoginService) {}

   onSubmit() {
      if (this.loginForm.valid) {
         let form = this.loginForm.value;

         this.loginService.login(form.login, form.password).subscribe((res) => {
            this.router.navigate(['/']);
         });
      }
   }
}
